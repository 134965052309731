import React from 'react'
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { AccountBox, CloudUpload } from '@material-ui/icons';
import { Link } from 'react-router-dom'

export default function CustomerMenu({ classes }) {
    return (
        <div>
            <List>
                <Link to="/">
                    <ListItem>
                        <ListItemIcon className={classes.icons}><CloudUpload /></ListItemIcon>
                        <ListItemText className={classes.menuText}>Field Sheets</ListItemText>
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <Link to="/account">
                <ListItem>
                    <ListItemIcon className={classes.icons}><AccountBox /></ListItemIcon>
                    <ListItemText className={classes.menuText}>Your Account</ListItemText>
                </ListItem>
            </Link>
        </div>
    )
}
