import { Avatar, Button, Card, CardHeader, Grid, LinearProgress } from '@material-ui/core'
import { CloudDownload, FileCopy } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import FieldSheetLogs from './FieldSheetLogs'
import JobProgress from './JobProgress'
import BackLink from '../../../shared/BackLink'

export default function FieldSheetDetail() {
    const { id } = useParams()
    const { retrieveSheet } = useDb()
    const [sheet, setSheet] = useState()

    console.log(sheet)

    const getSheet = async () => {
        const s = await retrieveSheet(id)
        setSheet(s)
    }

    useEffect(() => {
      const unsubscribe = getSheet()
      return unsubscribe
    }, [id])
    
    if(!sheet) {
        return <LinearProgress />
    }
    else return (
        <div>
            <h1>Field Sheet Detail</h1>
            <BackLink />
            <Card>
                <CardHeader
                    title={sheet.fileName}
                    avatar={<Avatar><FileCopy /></Avatar>}
                    subheader={`Submitted: ${moment(sheet.created.toDate()).fromNow()}`}
                    action={<Button
                        variant="contained"
                        color="primary"
                        startIcon={<CloudDownload />}
                        href={sheet.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Get Source File
                    </Button>}
                />
            </Card>
            <br/>
            <JobProgress
                fileName={sheet.fileName}
            />
            <br/>
            <FieldSheetLogs
                fileName={sheet.fileName}
            />
        </div>
    )
}
