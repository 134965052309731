import React from 'react'
import { Route } from 'react-router-dom'
import Forgot from '../components/auth/Forgot'
import SignIn from '../components/auth/SignIn'
import SignUp from '../components/auth/SignUp'

export default function PublicRoutes() {
    return (
        <>
            <Route exact path="/" component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route path="/forgot" component={Forgot} />
        </>
    )
}
