import { Button, Card, CardActions, CardContent, IconButton, Typography } from '@material-ui/core'
import { AddBox, ArrowForward } from '@material-ui/icons'
import React from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import SimDataGrid from '../../../shared/SimDataGrid'

export default function FieldSheets() {
    const { currentUser } = useAuth()
    const { GetSheets } = useDb()
    const sheets = GetSheets()
    console.log(sheets)

    const rows = sheets && sheets.map(s => ({
        ...s,
        timestamp: moment(s.created.toDate()).fromNow()
    }))

    const columns = [
        { field: 'timestamp', headerName: 'Created', width: 200 },
        { field: 'fileName', headerName: 'File Name', width: 300 },
        { 
            field: 'action',
            headerName: 'Action', 
            width: 100,
            renderCell: (params) => {
                return (
                    <div className="d-flex justify-content-between align-items-center">
                        <Link to={`/sheet-detail/${params.row.id}`}>
                            <IconButton>
                                <ArrowForward />
                            </IconButton>
                        </Link>
                    </div>
                );
            }
        },
    ]
    
    return (
        <div>
            <h1>Field Sheets</h1>
            <Card>
                <CardActions>
                    {
                        currentUser.good_standing
                        ?
                            currentUser.jobInProgress 
                            ?
                                <Typography>There is already a job in progress.</Typography>
                            :
                            <Link to="/new-sheet">
                                <Button
                                    variant="contained"
                                    startIcon={<AddBox />}
                                    color="primary"
                                >
                                    New Job
                                </Button>
                            </Link>
                        :
                        <Typography>Your account is inactive.</Typography>
                    }
                </CardActions>
                <CardContent>
                    <SimDataGrid
                        data={rows}
                        columns={columns}
                    />
                </CardContent>
            </Card>
        </div>
    )
}
