import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const config = {
    // Paste your Firebase config here
    apiKey: "AIzaSyAgdp8GsEPyRlw3zbIWtnkEW8k8L7WaCbE",
    authDomain: "fieldsheet-e62ed.firebaseapp.com",
    projectId: "fieldsheet-e62ed",
    storageBucket: "fieldsheet-e62ed.appspot.com",
    messagingSenderId: "620931674623",
    appId: "1:620931674623:web:cff1c20030171bf9c3b7ca"
}

const app = firebase.initializeApp(config);

export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();

export default app;