import { createTheme, ThemeProvider } from '@material-ui/core'
import React from 'react'
import { useAuth } from '../contexts/AuthContext'
import { DatabaseProvider } from '../contexts/DatabaseContext'
import PublicRoutes from '../routes/PublicRoutes'
import AdminPage from './admin/AdminPage'
import CustomerPage from './customer/CustomerPage'


// Set your theme colors and styles below. Details on Theming here: https://material-ui.com/customization/theming/#theming
const theme = createTheme({
    palette: {
        primary: {
            main: '#00b1e0',
            contrastText: '#fff'
        },
        mainMenuText: '#fff'
    }
    
})

export default function Wrapper() {
    const { currentUser } = useAuth()
    return (
        <ThemeProvider theme={theme}>
            {
                currentUser
                ?
                <DatabaseProvider>
                    { currentUser.admin ? <AdminPage /> : <CustomerPage /> }
                </DatabaseProvider>
                :
                <PublicRoutes />
            }
        </ThemeProvider>
    )
}
