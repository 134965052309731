import { Button } from '@material-ui/core'
import React from 'react'
import { useAuth } from '../../../contexts/AuthContext'

export default function CustomerAccount() {
    const { currentUser, logOut } = useAuth()

    return (
        <div>
            <h1>My Account</h1>
            Email: {currentUser.email}
            <br/><br/>
            <Button
                variant="outlined"
                color="secondary"
                onClick={() => logOut()}
            >
                Log Out
            </Button>
        </div>
    )
}
