import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import CustomerAccount from '../components/customer/account/CustomerAccount'
import FieldSheetDetail from '../components/customer/field-sheets/FieldSheetDetail'
import FieldSheets from '../components/customer/field-sheets/FieldSheets'
import NewFieldSheet from '../components/customer/field-sheets/NewFieldSheet'

export default function CustomerRoutes() {
    return (
        <>
            <Redirect from="/signup" to="/" />
            <Redirect from="/login" to="/" />
            <Route exact path="/" component={FieldSheets} />
            <Route exact path="/new-sheet" component={NewFieldSheet} />
            <Route exact path="/sheet-detail/:id" component={FieldSheetDetail} />
            <Route exact path="/account" component={CustomerAccount} />
        </>
    )
}
