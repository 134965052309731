import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'
import moment from 'moment'
import SimDataGrid from '../../../shared/SimDataGrid'
import { Card, CardContent } from '@material-ui/core'

export default function FieldSheetLogs({ fileName }) {
    const { GetRicaLogs } = useDb()
    const ricas = GetRicaLogs(fileName)
    console.log(ricas)

    const rows = ricas && ricas.map(r => ({
        ...r,
        sim: r.data.Reference,
        timestamp: moment(r.createdAt.toDate()).fromNow(),
        id: r.id
    }))

    const columns = [
        { field: 'timestamp', headerName: 'Time', width: 200 },
        { field: 'sim', headerName: 'SIM', width: 200 },
        { field: 'resultCode', headerName: 'Result', width: 120 },
        { field: 'messageDetails', headerName: 'Message', width: 700 }
    ]

    return (
        <div>
            <Card>
                <CardContent>
                    <SimDataGrid
                        data={rows}
                        columns={columns}
                    />
                </CardContent>
            </Card>
        </div>
    )
}
