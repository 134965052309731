import { Button, Card, CardContent, Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { ListAlt } from '@material-ui/icons'
import React, { useState } from 'react'
import { useAuth } from '../../../contexts/AuthContext'
import { useDb } from '../../../contexts/DatabaseContext'
import BackLink from '../../../shared/BackLink'
import FileUploader from '../../../shared/FileUploader'
import { useHistory } from 'react-router-dom'

export default function NewFieldSheet() {
    const history = useHistory()
    const { currentUser } = useAuth()
    const { createNewJob } = useDb()
    const [state, setState] = useState({
        termsChecked: false
    })

    const handleCheck = (e) => {
        setState({
            ...state,
            termsChecked: e.target.checked
        })
    }

    const getFile = async (f) => {
        try {
            const sheetId = await createNewJob({
                ...f
            })
            history.push(`/sheet-detail/${sheetId}`)
        }
        catch(err) {
            window.alert(err.message)
        }
    }

    return (
        <div>
            <h1>New Field Sheet</h1>
            <BackLink />
            {
                currentUser.jobInProgress
                ?
                <Typography>There is already a job in progress.</Typography>
                :
                <div>
                    <Card>
                        <CardContent>
                            <FormControlLabel
                                control={<Checkbox checked={state.termsChecked} onChange={handleCheck} name="termsChecked" />}
                                label={`I hereby confirm that I, ${currentUser.firstName} ${currentUser.lastName}, am a registered RICA officer and am authorised to register the SIM cards in this file. I am also hereby declaring that I have ensured that all the data captured in this data file is correct and accurate, in terms of the RICA Act.`}
                            />
                        </CardContent>
                    </Card>
                    <br/>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ListAlt />}
                        href="https://firebasestorage.googleapis.com/v0/b/fieldsheet-e62ed.appspot.com/o/SampleCSV.csv?alt=media&token=5dde9ed3-9fde-4afc-a5a3-bd14c13c794f"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Get Sample CSV File
                    </Button>
                    <br/><br/>
                    {
                        state.termsChecked && <FileUploader 
                            getFile={getFile}
                        />
                    }
                </div>
            }
        </div>
    )
}
