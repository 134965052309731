import { Box, Button, Card, CardActions, CardContent, LinearProgress, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { storage } from '../firebase'
import { CloudUpload } from '@material-ui/icons';

function LinearProgressWithLabel(props) {
    return (
    <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
        )}%`}</Typography>
        </Box>
    </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

export default function FileUploader({ getFile }) {
    const storageRef = storage.ref('uploads')
    const [fileSelected, setFileSelected] = useState(false);
    const [docUrl, setDocUrl] = useState(null)
    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState('')
    const [file, setFile] = useState(null);
 
    const handleFileChange = (e) => {
        
        if (e.target.files[0]) {
            setFile(e.target.files[0]);
            setFileSelected(true)
        }
    }

    const handleUploadStart = () => {
        setIsUploading(true)
        setProgress(0)
        console.log(file)
        const fileName = Math.random().toString(36).slice(-10)
        const uploadTask = storageRef.child(`${fileName}.csv`).put(file);
        uploadTask.on(
            'state_changed',
            snapshot => {
                var upProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(upProgress)
            },
            error => {
                setError(error)
                console.log(error)
                setIsUploading(false)
                setFileSelected(false)
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(url => {
                    getFile({
                        url,
                        fileName: `uploads/${fileName}.csv`
                    })
                    setProgress(100)
                    setIsUploading(false)
                    setFileSelected(false)
                })
            }
        )
    }

    return (
        <Card>
            <CardContent>
                Upload CSV File:<br/>
                
                <input type="file" onChange={handleFileChange} accept="application/csv"/>
                <LinearProgressWithLabel value={progress} />
                { error !== '' ? error.message : null }
            </CardContent>
            <CardActions className="iso-right">
                <Button disabled={!fileSelected || isUploading } onClick={handleUploadStart} startIcon={<CloudUpload />} variant="contained" color="primary">Upload</Button>
            </CardActions>
        </Card>
    )
}
