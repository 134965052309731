import React, { useContext, useEffect, useState } from 'react'
import { db } from '../firebase'
import { useAuth } from './AuthContext'

const DatabaseContext = React.createContext();

export function useDb() {
    return useContext(DatabaseContext)
}

export function DatabaseProvider({ children }) {
    const { currentUser } = useAuth()

    async function createNewJob(data) {
        return await db.collection("sheets")
        .add({
            ...data,
            created: new Date(),
            owner: currentUser.uid
        }).then(doc => {
            return doc.id
        })
    }

    async function retrieveSheet(sheetId) {
        return await db.collection("sheets")
        .doc(sheetId)
        .get()
        .then(doc => {
            return {
                ...doc.data(),
                id: doc.id
            }
        })
    }

    function GetRicaLogs(fileName) {
        const [ricas, setRicas] = useState([])
        useEffect(() => {
            const unsubscribe = db.collection('rica_logs')
            .where('sourceFile', '==', fileName)
            .orderBy('createdAt', 'desc')
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setRicas(data)
            })
            return unsubscribe
        }, [fileName])
        return ricas
    }

    function GetSheets() {
        const [sheets, setSheets] = useState([])
        useEffect(() => {
            const unsubscribe = db.collection('sheets')
            .where('owner', '==', currentUser.uid)
            .orderBy('created', 'desc')
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setSheets(data)
            })
            return unsubscribe
        }, [])
        return sheets
    }

    function GetJobs(fileName) {
        const [jobs, setJobs] = useState([])
        useEffect(() => {
            const unsubscribe = db.collection('jobs')
            .where('fileName', '==', fileName)
            .onSnapshot((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
                setJobs(data)
            })
            return unsubscribe
        }, [])
        return jobs
    }

    const value = {
        createNewJob,
        retrieveSheet,
        GetRicaLogs,
        GetSheets,
        GetJobs
    }
    
    return (
        <DatabaseContext.Provider value={value}>
            { children }
        </DatabaseContext.Provider>
    )
}
