import { Avatar, Button, Card, CardContent, CardHeader, LinearProgress } from '@material-ui/core'
import { Info } from '@material-ui/icons'
import React from 'react'
import { useDb } from '../../../contexts/DatabaseContext'

export default function JobProgress({ fileName }) {

    const { GetJobs } = useDb()
    const jobs = GetJobs(fileName)

    console.log(jobs)

    if(!jobs && jobs.length > 0) {
        return <LinearProgress />
    }
    else return (
        <div>
            <Card>
                <CardHeader
                    title="Job Progress"
                    avatar={<Avatar><Info /></Avatar>}
                    subheader={`Processed: ${jobs[0] && jobs[0].processedLines} / ${jobs[0] && jobs[0].totalLines}`}
                    action={<Button variant="outlined" color="primary">{jobs[0] && ((jobs[0].processedLines/jobs[0].totalLines)*100).toFixed(2)} %</Button>}
                />
                <CardContent>
                    <LinearProgress variant='determinate' value={jobs[0] && (jobs[0].processedLines/jobs[0].totalLines)*100} />
                </CardContent>
            </Card>
        </div>
    )
}
